import React, { Component } from "react";
import { API } from "aws-amplify";
import "./Contacts.css";
import Papa from "papaparse";
import CSVImportModal from "./CSVImportModal";
import ConfirmModal from "./ConfirmModal";

export default class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newContactName: "",
      newContactEmail: ""
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      // TODO goto homepage
      return;
    }

    try {
      const contacts = await this.contacts();
      this.setState({
        contacts: contacts.sort((a, b) => b.createdAt - a.createdAt)
      });
    } catch (e) {
      alert(e);
    }
  }

  contacts() {
    return API.get("contacts", "/contacts");
  }

  handleDeleteContact = async (contact) => {
    const id = contact.contactId;

    const confirmed = await this.refs.confirmModal.show({
      message: `Are you sure you'd like delete ${contact.email}?`,
      title: "Deleting Contact",
    });

    if (confirmed) {
      try {
        this.setState({ actionsDisabled: true });

        await API.del("contacts", `/contacts/${id}`);

        const contacts = await this.contacts();

        this.setState({
          contacts: contacts.sort((a, b) => b.createdAt - a.createdAt),
          actionsDisabled: false
        });
      } catch (e) {
        this.setState({ actionsDisabled: false });
        alert(e);
      }
    }
  }

  createContact(contact) {
    return API.post("contacts", "/contacts", {
      body: contact
    });
  }

  bulkCreateContacts(data) {
    return API.post("contacts", "/contacts/bulk", {
      body: data
    });
  }

  handleListDownload = (event) => {
    const contacts = Papa.unparse({ fields: ["email", "name"], data:this.state.contacts });
    const dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(contacts);
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "contacts.csv");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  handleDeleteAll = async (event) => {
    const contactIds = this.state.contacts.map(c => c.contactId);

    const confirmed = await this.refs.confirmModal.show({
      message:`Are you sure you'd like delete all ${contactIds.length} contacts?`,
      title: "Deleting All Contacts",
    });

    if (confirmed) {
      try {
        this.setState({ actionsDisabled: true });

        await API.del("contacts", "/contacts/bulk", { body: contactIds });

        const contacts = await this.contacts();
        this.setState({
          contacts: contacts.sort((a, b) => b.createdAt - a.createdAt),
          actionsDisabled: false
        });
      } catch (e) {
        this.setState({ actionsDisabled: false });
        alert(e);
      }
    }
  }

  validateInput = (event) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.newContactEmail);
  }

  handleFileChange = async (event) => {
    event.preventDefault();

    if (event.target.files && event.target.files.length > 0) {
      // TODO handle files with no header
      // TODO turn async instead of callback
      Papa.parse(event.target.files[0], {
        header: true,
        complete: async (results) => {
          const selectedColumns = await this.refs.csvImportModal.show({
            name: results.meta.fields[0],
            email: results.meta.fields[1],
            columns: results.meta.fields,
          });

          if (selectedColumns) {
            try {
              this.setState({ actionsDisabled: true });

              await this.bulkCreateContacts({
                contacts: results.data,
                columnNames: {
                  name: selectedColumns.name,
                  email: selectedColumns.email
                }
              });

              // trigger onboarding
              if (this.props.getOnboardingStage() === "contacts") {
                await this.props.setOnboardingStage("email");
              }

              const contacts = await this.contacts();

              this.setState({
                contacts: contacts.sort((a, b) => b.createdAt - a.createdAt),
                actionsDisabled: false
              });
            } catch (e) {
              this.setState({ actionsDisabled: false });

              alert(e.message);
            }
          }
        }
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleNewContactSubmit = async event => {
    event.preventDefault();

    try {
      this.setState({ actionsDisabled: true });

      await this.createContact({
        email: this.state.newContactEmail,
        name: this.state.newContactName
      });

      // trigger onboarding
      if (this.props.getOnboardingStage() === "contacts") {
        await this.props.setOnboardingStage("email");
      }

      const contacts = await this.contacts();

      this.setState({
        contacts: contacts.sort((a, b) => b.createdAt - a.createdAt),
        actionsDisabled: false,
        newContactEmail: "",
        newContactName: ""
      });
    } catch (e) {
      this.setState({ actionsDisabled: false });

      alert(e);
    }
  }

  renderContactsList(contacts) {
    const loading = this.state.actionsDisabled ? "" : null;

    const delistLabels = {
      bounce: "Bounced",
      complaint: "Complained",
      unsubscribe: "Unsubscribed",
    };

    return contacts.map(
      (contact) =>
        <tr key={contact.contactId}>
          <td title={contact.email}>
            {contact.email}
          </td>
          <td>{contact.name}</td>
          <td>{new Date(contact.createdAt).toLocaleString()}</td>
          <td>
            {contact.delisted ? <div className="info inline">{delistLabels[contact.delisted]}</div> : null}
            <button className="delete u-pull-right"
              onClick={() => this.handleDeleteContact(contact)}
              loading={loading}
              type="button">
              Delete
            </button>
          </td>
        </tr>
    );
  }

  renderContacts() {
    return (
      <div className="contacts-table">
        <table className="u-full-width">
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Created At</th>
              <th>
                <div className="dropdown">
                  <span className="dropdown-toggle u-pull-right">Actions &#x25BE;</span>
                  <div className="dropdown-menu">
                    <ul>
                      <li onClick={this.handleListDownload} className="dropdown-item">Export</li>
                      <li onClick={this.handleDeleteAll} className="dropdown-item">Delete All</li>
                    </ul>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.renderContactsList(this.state.contacts)}
          </tbody>
        </table>
      </div>
    );
  }

  renderContactForm() {
    const loading = this.state.actionsDisabled ? "" : null;

    return (
      <form onSubmit={this.handleNewContactSubmit}>
        <div className="row">
          <div className="six columns">
            <label htmlFor="newContactEmail">Email</label>
            <input autoComplete="off"
              onChange={this.handleChange}
              className="u-full-width"
              type="email"
              value={this.state.newContactEmail}
              placeholder="example@tiny-nps.com"
              id="newContactEmail" />
          </div>
          <div className="six columns">
            <label htmlFor="newContactName">Name</label>
            <input autoComplete="off"
              onChange={this.handleChange}
              className="u-full-width"
              type="text"
              value={this.state.newContactName}
              placeholder="Jane"
              id="newContactName" />
          </div>
        </div>
        <input loading={loading} disabled={!this.validateInput()} className="button-primary" type="submit" value="Add Contact" />
        <div className="u-pull-right">
          <label className="button" htmlFor="csvFile">Upload CSV</label>
          <input loading={loading} type="file" onChange={this.handleFileChange} id="csvFile" />
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="Contacts">
        {this.state.contacts && this.state.contacts.length === 0 &&
          <div className="info">
            This is where you can add, remove and edit your contacts — the people that will receive your NPS emails.
            <br />
            Start by entering contact information individually below or upload them in bulk by importing a CSV list*.
            <br />
            <small>*CSV list needs to have column headers. <a href="/example.csv" target="_blank">Here's an example</a> to get you started.</small>
          </div>
        }

        {this.renderContactForm()}
        {this.state.contacts && this.state.contacts.length > 0 && this.renderContacts()}

        <CSVImportModal ref="csvImportModal" />
        <ConfirmModal ref="confirmModal" />
      </div>
    );
  }
}
