import React, { Component } from "react";
import { API } from "aws-amplify";
import "./Unsubscribe.css";
import { parse } from "query-string";

export default class Unsubscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...parse(window.location.search),
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      const info = await this.getInfo();

      this.setState({ ...info, isLoading: false });
    } catch (e) {
      alert(e);
    }
  }

  getInfo() {
    return API.get("unsubscribe", `/unsubscribe?userId=${this.state.userId}`);
  }

  unsubscribe(data) {
    // if we don't have a responseId just don't do anything (for test email)
    if (!data.contactId) {
      return Promise.resolve();
    }
    return API.post("unsubscribe", "/unsubscribe", {
      body: data
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.unsubscribe({
        userId: this.state.userId,
        contactId: this.state.contactId,
      });
    } catch (e) { }

    this.setState({ showSuccess: true, isLoading: false });
  }

  render() {
    return (
      <div className="Unsubscribe">
        { !this.state.isLoading &&
          <form onSubmit={this.handleSubmit}>
            { this.state.logoUrl &&
              <img className="logo" src={this.state.logoUrl} />
            }
            { this.state.showSuccess &&
              <div className="info">You are now unsubscribed from {this.state.name}'s account.</div>
            }

            <p>{this.state.unsubscribeText}</p>

            <input
              type="submit"
              disabled={this.state.isLoading || this.state.showSuccess}
              className="button"
              value={this.state.showSuccess ? "Unsubscribed" : "Unsubscribe"} />
          </form>
        }
      </div>
    );
  }
}
