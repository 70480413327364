import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import AlertModal from "./AlertModal";
import "./Signup.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: [...Array(64)].map(i => (Math.random()*32 | 0).toString(32)).join(""),
      confirmationCode: "",
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      });
      this.setState({
        newUser
      });
    } catch (e) {
      await this.refs.alertModal.show({
        message: e.message || "Something went wrong.",
        title: "Oops",
      });
    }

    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      this.props.userHasAuthenticated(true);

      // trigger onboarding
      await this.props.setOnboardingStage("settings");

      this.props.history.push("/settings");
    } catch (e) {
      await this.refs.alertModal.show({
        message: e.message || "Something went wrong.",
        title: "Oops",
      });
      this.setState({ isLoading: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <img className="logo" src="/images/tiny-nps-logo.png" />
        <p>
          We've sent you a confirmation email to {this.state.email} 📥
          <br />
          Please enter the verification code in the email we've sent.
          <br />
          <small>Don't forget to check your spam folder and double check your email address if you can't find it.</small>
        </p>
        <label htmlFor="confirmationCode">Code</label>
        <input onChange={this.handleChange}
          value={this.state.confirmationCode}
          className="u-full-width"
          type="tel"
          placeholder=""
          id="confirmationCode" />
        <input disabled={!this.validateConfirmationForm()}
          className={"u-full-width button-primary" + (this.state.isLoading ? " loading" : "")}
          type="submit"
          value="Verify" />
      </form>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <img className="logo" src="/images/tiny-nps-logo.png" />
        <p>
          Getting started with TinyNPS is easy ✌️
          <br />
          Just enter your email address — no password required.
        </p>
        <label htmlFor="email">Email</label>
        <input onChange={this.handleChange}
          value={this.state.email}
          className="u-full-width"
          type="email"
          placeholder="example@tiny-nps.com"
          id="email" />

        <input disabled={!this.validateForm()}
          className={"u-full-width button-primary" + (this.state.isLoading ? " loading" : "")}
          type="submit"
          value="Sign Up" />
        <small>Already have an account? Login <Link to="login">here</Link>.</small>
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
        <AlertModal ref="alertModal" />
      </div>
    );
  }
}
