import React from "react";
import PromiseModal from "./PromiseModal";

export default class PromptModal extends PromiseModal {
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  renderBody() {
    return (
      <div className="modal-container">
        <div className="modal">
          <form onSubmit={(e) => { e.preventDefault(); this.resolve(this.state.response); }}>
            <h3>{this.state.title}</h3>
            <p dangerouslySetInnerHTML={{__html: this.state.message}} />
            <input type="text"
              id="response"
              onChange={this.handleChange}
              className="u-full-width"
              value={this.state.response} />
            <input className="button-primary" type="submit" value="OK" />
          </form>
        </div>
      </div>
    );
  }
}
