const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "tinynps-app-api-dev-attachmentsbucket-114d9r0rplivx",
    BASE_URL: "https://files-dev.tiny-nps.com/public/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://whd0au8wja.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_otxZoC8T0",
    APP_CLIENT_ID: "3fo50hv3j7rmkr2m9nqndd2c8l",
    IDENTITY_POOL_ID: "us-east-1:2cdf056a-b3b9-4cf3-a371-160f886895a2"
  }
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "tinynps-app-api-production-attachmentsbucket-qgvewlzmhu9j",
    BASE_URL: "https://files.tiny-nps.com/public/"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.tiny-nps.com"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_I9SW7Hhj8",
    APP_CLIENT_ID: "4tjaak9vv1iatqsg725q6td0d0",
    IDENTITY_POOL_ID: "us-east-1:499d27fc-9407-40a9-8207-caefb87ff3ac"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 500000,
  ...config
};
