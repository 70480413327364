import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import "./Templates.css";

export default class Templates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      templates: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const templates = await this.templates();
      this.setState({ templates: templates.sort((a, b) => b.createdAt - a.createdAt) });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  templates() {
    return API.get("templates", "/templates");
  }

  renderTemplatesList(templates) {
    return templates.map(
      (template) =>
        <tr key={template.templateId}>
          <td>{template.internalName || template.subject}</td>
          <td>{new Date(template.createdAt).toLocaleString()}</td>
          <td>
            <div className="info inline">{template.sent ? "Sent" : "Draft"}</div>
            <Link className="button action u-pull-right"
              to={"/email/" + template.templateId}>
              {template.sent ? "View" : "Edit"}
            </Link>
          </td>
        </tr>
    );
  }

  renderTemplates() {
    return (
      <div className="templates-table">
        <table className="u-full-width">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.renderTemplatesList(this.state.templates)}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="Templates">
        {!this.state.isLoading && this.state.templates.length === 0 &&
          <div className="info">
            Now that you've added your contacts, you can start collecting NPS feedback 👍
            <br />
            To start collecting feedback, compose a feedback request email. If you're not ready to start sending, you can always save your work as a draft and get back to it later.
          </div>
        }

        {!this.state.isLoading &&
          <div className="compose-template">
            <Link to="/email/new" className="button button-primary">Compose</Link>
          </div>
        }
        {!this.state.isLoading && this.state.templates.length > 0 && this.renderTemplates()}
      </div>
    );
  }
}
