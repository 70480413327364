import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const {pathname} = this.props.location;

      this.setState({
        user,
        route: pathname,
        onboardingStage: user.attributes ? user.attributes["custom:onboardingStage"] : null
      });

      this.userHasAuthenticated(true);
    } catch(e) {
      if (e !== "not authenticated") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const { pathname } = location;

      this.setState({ route: pathname });

      // Use setTimeout to make sure this runs after React Router's own listener
      setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === 'POP') {
          return;
        }
        // In all other cases, check fragment/scroll to top
        var hash = window.location.hash;
        if (hash) {
          var element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({block: 'start', behavior: 'smooth'});
          }
        } else {
         window.scrollTo(0, 0);
        }
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  userHasAuthenticated = authenticated => {

    if (authenticated) {
      // no need for async/await
      Auth.currentAuthenticatedUser()
        .then((user) => {
          window.PisanoWidget.boot("PSN-nqw5bpx", {
            customer: {
              email: user.attributes.email,
              name: user.attributes.name,
            }
          });
        });
    } else {
      window.PisanoWidget.destroy();
    }

    this.setState({ isAuthenticated: authenticated });
  }

  setOnboardingStage = async (stage) => {
    try {
      if (!this.state.user) {
        const user = await Auth.currentAuthenticatedUser();
        this.setState({ user });
      }

      await Auth.updateUserAttributes(this.state.user, {
        "custom:onboardingStage": stage
      });

      this.setState({ onboardingStage: stage });
    } catch (e) {
      console.error(e);
    }
  }

  getOnboardingStage = () => {
    return this.state.onboardingStage;
  }

  renderNav() {
    return (
      <nav className="navbar">
          <img className="logo" src="/images/tiny-nps-wordmark.png" />
          <ul className="navbar-list">
            <li className="navbar-item">
              <Link className="navbar-link" to="/email">Email</Link>
              { this.state.route && !this.state.route.includes("/email") && this.state.onboardingStage == "email" && <div className="tip">You're All Set: Get Feedback!</div> }
            </li>
            <li className="navbar-item">
              <Link className="navbar-link" to="/contacts">Contacts</Link>
              { this.state.route && !this.state.route.includes("/contacts") && this.state.onboardingStage == "contacts" && <div className="tip">Add Contacts</div> }
            </li>
            <li className="navbar-item">
              <Link className="navbar-link" to="/settings">Settings</Link>
              { this.state.route && !this.state.route.includes("/settings") && this.state.onboardingStage == "settings" && <div className="tip">Enter Your Info</div> }
            </li>
          </ul>
      </nav>
    );
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setOnboardingStage: this.setOnboardingStage,
      getOnboardingStage: this.getOnboardingStage
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        {this.state.isAuthenticated ? this.renderNav() : null}

        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);
