import { Storage } from "aws-amplify";
import { Auth } from "aws-amplify";

export async function s3Upload(file) {
  const user = await Auth.currentAuthenticatedUser();
  const attributes = user.attributes;
  const filename = `${Date.now()}-${attributes.sub}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}
