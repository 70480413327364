// This function is to clean and parse quill html output and make it more
// suitable for our backend
export function parseHTML(html) {
  /* <p>Hi ,</p><p><br></p><p>On a scale from 0 to 10, how recommendable are we?</p><p><br></p><div class="NPS" contenteditable="false"></div><p><br></p><p>Thanks,</p><p>Pisano</p> */
  let template = document.createElement("div");
  html = html.trim();
  template.innerHTML = html;
  template.querySelectorAll(".NPS").forEach(n => n.outerHTML = "{buttons}");
  template.querySelectorAll(".contact-name")
    .forEach(n => n.outerHTML = "{" + n.firstElementChild.innerHTML.trim() + "}");
  let result = template.innerHTML;
  return result;
}

// This function is to replace backend content representations with
// quill friendly html
export function unparseHTML(string) {
  const nameOrSpan = defaultValue => `<span class="contact-name" data-default-value="${defaultValue}">﻿<span contenteditable="false"> name or '${defaultValue}' </span>﻿</span>`;
  const nameSpan = `<span class="contact-name">﻿<span contenteditable="false"> name </span>﻿</span>`;
  const buttonsDiv = `<div class="NPS" contenteditable="false"></div>`;

  const regexOnlyName = /\{name}/g;
  const regexButtons = /\{buttons}/g;
  const regexNameOr = /\{name or '.+?'}/g;

  const result = string.replace(regexOnlyName, (match) => {
    return nameSpan;
  }).replace(regexNameOr, (match) => {
    return nameOrSpan(match.substring(10, match.length - 2));
  }).replace(regexButtons, (match) => {
    return buttonsDiv;
  });

  return result;
}

export function stripHTML(html) {
  const regexPlaceholders = /\{[^}]*}/gi;
  const regexHTML = /(<([^>]+)>)/ig;
  return html.replace(regexHTML, "").replace(regexPlaceholders, "");
}
