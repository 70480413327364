import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { parse } from "query-string";
import "./Login.css";
import config from "../config";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSent: false,
      isLoggingIn: false,
      email: ""
    };
  }

  async componentDidMount() {
    const params = parse(window.location.search);
    if (params.code) {
      this.setState({ isLoggingIn: true });

      const userPoolData = {
        UserPoolId: config.cognito.USER_POOL_ID,
        ClientId: config.cognito.APP_CLIENT_ID,
      };

      try {
        const userPool = new CognitoUserPool(userPoolData);
        const userData = {
          Username: window.localStorage.getItem("__u"),
          Pool: userPool,
        }
        const user = new CognitoUser(userData);
        user.Session = window.localStorage.getItem("__s");

        await Auth.sendCustomChallengeAnswer(user, params.code)
        this.props.userHasAuthenticated(true);
      } catch (e) {
        // TODO show something
        alert(e.message);
      }
    }
  }

  validateForm() {
    return this.state.email.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(this.state.email);
      window.localStorage.setItem("__s", user.Session);
      window.localStorage.setItem("__u", user.username);
      this.setState({ ...this.state, isLoading: false, isSent: true });

    } catch (e) {
      // TODO show something
      alert(e.message);
      this.setState({ ...this.state, isLoading: false });
    }
  }

  render() {
    if (this.state.isLoggingIn) {
      return (
        <div className="Login">
          <div className="login-info">
            <img className="logo" src="/images/tiny-nps-logo.png" />
            <p>Just a moment, we're logging you in.</p>
          </div>
        </div>
      )
    }
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <img className="logo" src="/images/tiny-nps-logo.png" />

          { this.state.isSent &&
            <div className="info">
              We've sent you an email to {this.state.email} with your login link.
              <br />
              <small>Don't forget to check your spam folder and double check your email address if you can't find it.</small>
            </div>
          }
          { !this.state.isSent &&
            <div>
              <p>Welcome back 👋<br />Just enter your email address to login.</p>
              <label htmlFor="email">Email</label>
              <input onChange={this.handleChange}
                disabled={this.state.isSent}
                value={this.state.email}
                className="u-full-width"
                type="email"
                placeholder="example@tiny-nps.com"
                id="email" />
              <input disabled={!this.validateForm()}
                className={"u-full-width button-primary" + (this.state.isLoading ? " loading" : "")}
                type="submit"
                value="Login" />
              <small>Don't have an account? Get started <Link to="/signup">here</Link> for free.</small>
            </div>
          }
        </form>
      </div>
    );
  }
}
