import React from "react";
import { Route, Redirect } from "react-router-dom";
import { parse } from "query-string";

export default ({ component: C, props: cProps, ...rest }) => {
  const redirect = parse(window.location.search).redirect;
  return (
    <Route
      {...rest}
      render={props =>
        !cProps.isAuthenticated
          ? <C {...props} {...cProps} />
          : <Redirect
              to={(redirect === "" || redirect == null) ? "/email" : redirect}
            />}
    />
  );
};
