import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Landing.css";

export default class Privacy extends Component {
  render() {
    return (
      <div className="Landing what-is-nps">
        <img className="logo" src="/images/tiny-nps-wordmark.png" height="64" />

        <h2>What is NPS®?</h2>

        <p>NPS stands for Net Promoter Score, or sometimes, Net Promoter System.</p>
        <p>It's a system that is used to gauge customer (or user, guest, employee) loyalty.</p>
        <p>This system is quite popular with more than two thirds of Fortune 1000 companies using it.</p>
        <p>Calculated based on responses to a single question, <em>"How likely is it that you would recommend our company/product/service to a friend or colleague?"</em>, the scoring is most often based on a 0 to 10 scale.</p>
        <p>By identifying who your promoters, passives and detractors are, it helps you focus.</p>
        <p>This makes it really versatile. You can use it to:</p>
        <ul>
          <li>📣 Make special offers and win back people that are — kind of — meh about your services.</li>
          <li>😱 Identify problems before they get out of hand.</li>
          <li>🙏 Ask for small favors from people that rave about you. Like tweeting about their experiences.</li>
        </ul>
        <p>And more. It's really up to your imagination!</p>
        <p>So start using NPS with TinyNPS for free and see for yourself 😊</p>
        <Link className="button button-primary start" to="/signup">Get Started</Link>
      </div>
    );
  }
}
