import React from "react";
import PromiseModal from "./PromiseModal";

export default class ConfirmModal extends PromiseModal {
  renderBody() {
    return (
      <div className="modal-container">
        <div className="modal">
          <form onSubmit={(e) => { e.preventDefault(); this.resolve(true); }}>
            <h3>{this.state.title}</h3>
            <p dangerouslySetInnerHTML={{__html: this.state.message}} />
            <input className="button-primary" type="submit" value="Yes" />
            <div className="u-pull-right">
              <button onClick={(e) => { e.preventDefault(); this.resolve(false); }}>No</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
