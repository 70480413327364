import React from "react";

// based on https://github.com/nextjs-boilerplate/react-promise-modal

export default class PromiseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.promiseInfo = {};
  }

  show(state) {
    return new Promise((resolve, reject) => {
      this.promiseInfo = {
        resolve,
        reject,
      };
      this.setState({
        show: true,
        ...state
      });
    })

  }

  hide() {
    this.setState({
      show: false,
    });
  }

  resolve(result) {
    this.hide();
    this.promiseInfo.resolve(result);
  }

  reject(result) {
    this.hide();
    return this.promiseInfo.reject(result);
  }

  renderBody() {
    return null;
  }

  render() {
    const { show } = this.state;
    if (!show) return null;
    return this.renderBody();
  }
}
