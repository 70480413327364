import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Landing.css";

export default class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <div className="Landing">
        <div className="row heading">
          <div className="six columns">
            <img className="logo" title="TinyNPS: Free NPS tool" src="/images/tiny-nps-wordmark.png" height="64" />
            <p>Hi there,</p>
            <p>TinyNPS is a feedback tool that makes measuring NPS® a breeze.</p>
            <p>It takes care of sending out emails, calculating scores and keeping track of contacts.</p>
            <p>So you can focus on your feedback.</p>
            <p>Oh and it's free 😍</p>
            <Link className="button button-primary start" to="/signup">Get Started</Link>
            <Link className="button" to="/login">Login</Link>
            <p><small>Made with ❤️ by the fine folks at <a href="https://www.pisano.co" rel="noopener noreferrer" target="_blank">Pisano</a>.</small></p>
          </div>
          <div className="six columns">
            <img className="u-full-width hero" src="/images/hero.png" />
          </div>
        </div>

        <div className="row features">
          <div className="four columns">
            <img src="/images/arrow.png" height="48" />
            <h5>Add Your Contacts</h5>
            <p>Add a list of your users, employees or customers simply by uploading a CSV file.</p>
          </div>
          <div className="four columns">
            <img src="/images/binder.png" height="48" />
            <h5>Write Your Questions</h5>
            <p>Customize your NPS and open-ended followup questions with the built-in text editor.</p>
          </div>
          <div className="four columns">
            <img src="/images/airplane.png" height="48" />
            <h5>Send Your Email</h5>
            <p>Collect one-click feedback from all your contacts with personalized emails.</p>
          </div>
        </div>

        <div className="row features">
          <div className="twelve columns">
            <Link className="button" to="/about-nps">Wait 🤔 What the Heck is NPS?</Link>
          </div>
        </div>
      </div>
    );
  }
}
