import React, { Component } from "react";
import { API } from "aws-amplify";
import "./Respond.css";
import { parse } from "query-string";

export default class Respond extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...parse(window.location.search),
      isLoading: true,
      hasResponded: false,
      content: ""
    };
  }

  async componentDidMount() {
    try {
      const check = await this.checkResponse();

      // check if we responded yet
      if (check.responded && !this.state.responseId) {
        this.setState({ hasResponded: true, isLoading: false });
      } else {
        const flow = await this.getFlow();

        // add custom code
        if (flow.customJavaScript) {
          const elem = document.createElement("div");
          elem.innerHTML = flow.customJavaScript;

          for (const e of elem.children) {
            // if we've got a script tag, manually add it to the DOM
            // otherwise HTML5 spec prevents it from executing
            if (e.tagName === "SCRIPT") {
              let scr = document.createElement("script");
              for (const a of e.attributes) {
                scr.setAttribute(a.name, a.value);
              }
              scr.innerHTML = e.innerHTML;
              document.head.appendChild(scr);
            } else {
              document.head.appendChild(e);
            }
          }
        }

        // we responded but no comment yet
        if (!this.state.responseId) {
          const { userId, responseId, templateId, email, nps } = await this.createResponse();

          // call listeners for custom code
          if (window.tnyOnUserResponse) window.tnyOnUserResponse(email, nps);

          this.props.history.replace(`/respond?responseId=${responseId}&userId=${userId}&templateId=${templateId}`);

          this.setState({
            responseId
          });
        }

        this.setState({
          ...flow,
          isLoading: false
        });
      }

    } catch (e) {
      alert(e);
    }
  }

  getFlow() {
    return API.get("templates", `/templates/${this.state.templateId}/flow?userId=${this.state.userId}`);
  }

  createResponse() {
    const { userId, contactId, templateId, nps } = this.state;
    // if we don't have a contact just don't do anything (for test email)
    if (!contactId) {
      return Promise.resolve({ userId, templateId, nps, responseId: ""});
    }
    return API.post("responses", "/responses", {
      body: { userId, contactId, templateId, nps }
    });
  }

  checkResponse() {
    const { templateId, userId, contactId } = this.state;
    // if we don't have a contact just don't do anything (for test email)
    if (!contactId) {
      return Promise.resolve({ responded: false });
    }
    return API.get("responses", `/responses/check?userId=${userId}&templateId=${templateId}&contactId=${contactId}`);
  }

  addComment() {
    const { content, userId, responseId } = this.state;
    // if we don't have a responseId just don't do anything (for test email)
    if (!responseId) {
      return Promise.resolve();
    }
    return API.put("responses", `/responses/${responseId}`, {
      body: {
        userId,
        content
      }
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const {email, responseId, content} = await this.addComment();

      // call listeners for custom code
      if (window.tnyOnUserComment) window.tnyOnUserComment(email, content);
    } catch (e) { }

    this.setState({ isLoading: false, showThanks: true });
  }

  render() {
    let question;

    if (this.state.nps < 7) {
      question = this.state.detractorCommentQuestion;
    } else if (this.state.nps >= 7 && this.state.nps <= 8) {
      question = this.state.passiveCommentQuestion;
    } else {
      question = this.state.promoterCommentQuestion;
    }

    return (
      <div className="Respond">
        { this.state.hasResponded && !this.state.isLoading &&
          <div className="box">
            <div className="info">
              You've already responded to this email.
            </div>
          </div>
        }

        { !this.state.hasResponded && !this.state.showThanks && !this.state.isLoading &&
          <form onSubmit={this.handleSubmit}>
            { this.state.logoUrl &&
              <img className="logo" src={this.state.logoUrl} />
            }
            <div dangerouslySetInnerHTML={{__html: question}} />
            <textarea onChange={this.handleChange}
              className="u-full-width"
              type="text"
              placeholder=""
              id="content" />

            <input
              type="submit"
              className={"button" + (this.state.isLoading ? " loading" : "")}
              value="Add Comment" />
          </form>
        }
        { this.state.showThanks &&
          <div className="box">
            { this.state.logoUrl &&
              <img className="logo" src={this.state.logoUrl} />
            }
            <div className="thanks" dangerouslySetInnerHTML={{__html: this.state.thankMessage}} />
          </div>
        }
      </div>
    );
  }
}
