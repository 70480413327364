import React, { Component } from "react";
import { API } from "aws-amplify";
import "./Template.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { parseHTML, unparseHTML } from "../libs/util"
import Papa from "papaparse";
import AlertModal from "./AlertModal";
import ConfirmModal from "./ConfirmModal";
import PromptModal from "./PromptModal";
import TestSendModal from "./TestSendModal";

// TODO move this stuff elsewhere
// Quill Customizations
let BlockEmbed = Quill.import('blots/block/embed');
let Embed = Quill.import('blots/embed');

class ContactNameBlot extends Embed {
  static create(defaultValue) {
    let node = super.create();
    if (defaultValue && defaultValue.length > 0) {
      node.innerHTML = (" name or '" + defaultValue + "' ");
      node.dataset.defaultValue = defaultValue;
    } else {
      node.innerHTML = (" name ");
    }
    return node;
  }
  static value(domNode) {
    return domNode.dataset.defaultValue || "";
  }
}

ContactNameBlot.blotName = "ContactName";
ContactNameBlot.tagName = "span";
ContactNameBlot.className = "contact-name";
Quill.register('formats/ContactName', ContactNameBlot);

// This is set in the render function of our "Template" component below
let component = null;

function insertContactName() {
  component.refs.promptModal.show({
    message: "Please set a default value for the name token. This value will be inserted into the email if the recipient's name is unknown.",
    response: "there",
    title: "Recipient Name Token",
  }).then((defaultValue) => {
    if (defaultValue != null) {
      this.quill.focus();
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, "ContactName", defaultValue, Quill.sources.USER);
      this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
    }
  });
}

class NPSBlot extends BlockEmbed {
  static create(defaultValue) {
    let node = super.create();
    node.setAttribute("contenteditable", "false");
    return node;
  }
}

NPSBlot.blotName = "NPS";
NPSBlot.tagName = "div";
NPSBlot.className = "NPS";
Quill.register('formats/NPS', NPSBlot);

function insertNPS() {
  let range = this.quill.getSelection(true);
  this.quill.deleteText(range.index, range.length);
  this.quill.insertEmbed(range.index, "NPS", Quill.sources.USER);
  this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
}

export default class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isDeleting: null,
      isSending: null,
      template: null
    };
  }

  async setup() {
    try {
      const id = this.props.match.params.id;
      let template = {
        internalName: "",
        subject: "We would love your feedback",
        content: "<p>Hi {name or 'there'},</p><p><br></p><p>On a scale from 0 to 10, how likely are you to recommend us to a friend or colleague?</p><p><br></p>{buttons}<p><br></p><p>Best.</p>",
        thankMessage: "<p>Thank you!</p>",
        passiveCommentQuestion: "<p>Thank you. What could we improve?</p>",
        promoterCommentQuestion: "<p>Wonderful! Is there anything you'd like to add?</p>",
        detractorCommentQuestion: "<p>Thank you for your honesty. We'd be grateful if you could provide some details about how we could improve.</p>",
        customJavaScript: "<script>\n// This is called when the user responds to your email\nwindow.tnyOnUserResponse = function(email, nps) { console.log(email, nps); };\n\n// This is called when the user adds a comment to the response\nwindow.tnyOnUserComment = function(email, comment) { console.log(email, comment); };\n</script>"
      };

      if (id !== "new") {
        template = await this.getTemplate();
      }

      let stats = null;

      if (template.sent) {
        stats = await this.getTemplateStats();
      }

      this.setState({
        stats,
        isLoading: null,
        isDeleting: null,
        isSending: null,
        template: {
          ...template,
          content: unparseHTML(template.content),
          thankMessage: unparseHTML(template.thankMessage),
          passiveCommentQuestion: unparseHTML(template.passiveCommentQuestion),
          promoterCommentQuestion: unparseHTML(template.promoterCommentQuestion),
          detractorCommentQuestion: unparseHTML(template.detractorCommentQuestion),
        }
      });
    } catch (e) {
      alert(e);
    }
  }

  async componentDidMount() {
    await this.setup();
  }

  getTemplateStats() {
    return API.get("templates", `/templates/${this.props.match.params.id}/stats`);
  }

  getTemplate() {
    return API.get("templates", `/templates/${this.props.match.params.id}`);
  }

  testSendTemplate(template) {
    return API.put("templates", `/templates/${this.props.match.params.id}/test`, {
      body: template
    });
  }

  sendTemplate(template) {
    return API.put("templates", `/templates/${this.props.match.params.id}/send`, {
      body: template
    });
  }

  saveTemplate(template) {
    if (this.props.match.params.id === "new") {
      return API.post("templates", "/templates", {
        body: template
      });
    } else {
      return API.put("templates", `/templates/${this.props.match.params.id}`, {
        body: template
      });
    }
  }

  deleteTemplate() {
    return API.del("templates", `/templates/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.template &&
      this.state.template.content && this.state.template.content.length > 0 &&
      this.state.template.subject && this.state.template.subject.length > 0;
  }

  handleChange = (target, eventOrValue) => {
    // TODO replace interpolation strings
    this.setState({
      template: {
        ...this.state.template,
        [target]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const id = (await this.saveTemplate({
        ...this.state.template,
        content: parseHTML(this.state.template.content),
      })).templateId;

      this.props.history.replace("/email/" + id);
      this.setup();

    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleTestSend = async event => {
    // TODO check if there are buttons
    event.preventDefault();

    const recipient = await this.refs.testSendModal.show({
      name: "",
      email: "",
    });

    if (!recipient) {
      return;
    }

    this.setState({ isSending: true });

    try {
      const id = (await this.testSendTemplate({
        ...this.state.template,
        content: parseHTML(this.state.template.content),
        recipient
      })).templateId;

      this.props.history.replace("/email/" + id);
      this.setup();

      await this.refs.alertModal.show({
        message: `Sent an email to ${recipient.email} 👍 <br> While we were at it, we also saved this email as a draft.`,
        title: "All good",
      });

    } catch (e) {
      this.setState({ isSending: false });

      const message = e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages.join("<br>");

      await this.refs.alertModal.show({
        message: message || "Something went wrong.",
        title: "Oops",
      });
    }
  }

  handleSend = async event => {
    // TODO check if there are buttons
    event.preventDefault();

    const confirmed = await this.refs.confirmModal.show({
      message: "Are you sure you want to send this email to <strong>all</strong> your contacts now?",
      title: "Sending Now",
    });

    if (!confirmed) {
      return;
    }

    this.setState({ isSending: true });

    try {
      const id = (await this.sendTemplate({
        ...this.state.template,
        content: parseHTML(this.state.template.content),
      })).templateId;

      this.props.history.replace("/email/" + id);

      // trigger onboarding
      if (this.props.getOnboardingStage() === "email") {
        await this.props.setOnboardingStage("done");
      }

      this.setup();

    } catch (e) {
      this.setState({ isSending: false });

      const message = e.response &&
        e.response.data &&
        e.response.data.messages &&
        e.response.data.messages.join("<br>");

      await this.refs.alertModal.show({
        message: message || "Something went wrong.",
        title: "Oops",
      });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = await this.refs.confirmModal.show({
      message: "Are you sure you want to delete this draft? You can't undo this!",
      title: "Deleting Draft"
    });

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteTemplate();
      this.props.history.replace("/email");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  handleListDownload = (type = "all") => {
    let data = this.state.template.responses;

    // filter based on type
    if (type === "detractors") {
      data = data.filter(r => r.isDetractor);
    } else if (type === "promoters") {
      data = data.filter(r => r.isPromoter);
    } else if (type === "passives") {
      data = data.filter(r => r.isPassive);
    }

    // fix dates
    data = data.map(d => {
      return {
        ...d,
        createdAt: new Date(d.createdAt).toLocaleString()
      }
    });

    const responses = Papa.unparse({ fields: ["responseId", "content", "email", "nps", "createdAt"], data });
    const dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(responses);
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `responses-${type}.csv`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  renderDeliveryStats() {
    return (
      <table className="fixed u-full-width">
        <thead>
          <tr>
            <th>Sent</th>
            <th>Delivered</th>
            <th>Soft Bounced</th>
            <th>Hard Bounced</th>
            <th>Compained</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{Object.values(this.state.stats).reduce((a, b) => a + b)}</td>
            <td>{this.state.stats.delivered}</td>
            <td>{this.state.stats.softBounce}</td>
            <td>{this.state.stats.bounce}</td>
            <td>{this.state.stats.complaint}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderResponseStats() {
    let NPS = "N/A";
    let count = 0;
    let detractors = 0;
    let promoters = 0
    let passives = 0;

    if (this.state.template && this.state.template.responses && this.state.template.responses.length > 0) {
      const responses = this.state.template.responses;
      detractors = responses.filter(r => r.isDetractor).length;
      promoters = responses.filter(r => r.isPromoter).length;
      passives = responses.filter(r => r.isPassive).length;

      count = this.state.template.responses.length;
      NPS = (promoters - detractors) / count * 100 | 0;
    }

    return (
      <div className="stats row">
        <div className="three columns">
          <h1>{NPS}</h1>
          <p>NPS</p>
        </div>
        <div className="three columns">
          <h1>{promoters}</h1>
          <p>Promoters</p>
        </div>
        <div className="three columns">
          <h1>{passives}</h1>
          <p>Passives</p>
        </div>
        <div className="three columns">
          <h1>{detractors}</h1>
          <p>Detractors</p>
        </div>
      </div>
    );
  }

  renderResponses() {
    return (
      <div className="responses-table">
        <table className="u-full-width">
          <thead>
            <tr>
              <th>Comment</th>
              <th>Score</th>
              <th>Email</th>
              <th>
                <div className="dropdown">
                  <span className="dropdown-toggle u-pull-right">Export &#x25BE;</span>
                  <div className="dropdown-menu">
                    <ul>
                      <li onClick={e => this.handleListDownload()} className="dropdown-item">All</li>
                      <li onClick={e => this.handleListDownload("promoters")} className="dropdown-item">Promoters</li>
                      <li onClick={e => this.handleListDownload("passives")} className="dropdown-item">Passives</li>
                      <li onClick={e => this.handleListDownload("detractors")} className="dropdown-item">Detractors</li>
                    </ul>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            { this.state.template.responses && this.state.template.responses.length === 0 &&
              <tr>
                <td>—</td>
                <td>—</td>
                <th colSpan="2">—</th>
              </tr>
            }
            { this.state.template.responses && this.state.template.responses.map(
              (response) =>
                <tr key={response.responseId}>
                  <td>{response.content}</td>
                  <td>{response.nps} / 10</td>
                  <td colSpan="2">{response.email}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    );
  }

  renderComposeForm() {
    // set file wide component var
    component = this;

    return (
      <div className={"compose-form" + (this.state.template.sent ? " read-only" : "") }>
        <form onSubmit={this.handleSubmit}>
          { !this.state.template.sent &&
            <label>Name <div className="info inline">The internal name of your email. Email subject line will be used if left empty.</div></label>
          }
          { !this.state.template.sent &&
            <input onChange={event => this.handleChange("internalName", event)}
              value={this.state.template && this.state.template.internalName}
              className="u-full-width"
              type="text"
              placeholder="" />
          }

          <label>Subject</label>
          <input onChange={event => this.handleChange("subject", event)}
            value={this.state.template && this.state.template.subject}
            className="u-full-width"
            type="text"
            placeholder="" />

          <label>Body</label>
          <div id="toolbar">
            <span className="ql-formats">
              <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                <option value="1"></option>
                <option value="2"></option>
                <option />
              </select>
            </span>
            <span className="ql-formats">
              <button className="ql-bold" />
              <button className="ql-italic" />
              <button className="ql-underline" />
              <button className="ql-link" />
            </span>
            <span className="ql-formats">
              <button className="ql-list" value="ordered" />
              <button className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
              <button className="ql-insert-nps">
                <svg viewBox="0 0 88 88" className="ql-fill">
                  <path d="M43.85,83.723c-22.444,0-40.704-18.26-40.704-40.704S21.406,2.315,43.85,2.315s40.704,18.26,40.704,40.704   S66.294,83.723,43.85,83.723z M43.85,10.315c-18.033,0-32.704,14.671-32.704,32.704S25.817,75.723,43.85,75.723   s32.704-14.671,32.704-32.704S61.883,10.315,43.85,10.315z"></path>
                  <path d="M45.534,69.37h-4.423c-0.878,0-1.59-0.712-1.59-1.59v-5.874c0-0.878,0.712-1.59,1.59-1.59h4.423    c0.878,0,1.59,0.712,1.59,1.59v5.874C47.124,68.658,46.412,69.37,45.534,69.37z M44.989,57.222h-3.514    c-0.868,0-1.575-0.695-1.59-1.563c-0.042-2.531,0.118-4.876,0.477-6.969c0.39-2.28,1.481-4.471,3.242-6.511    c0.903-1.041,1.865-2.077,2.861-3.083c0.92-0.928,1.781-1.887,2.559-2.851c0.735-0.912,1.358-1.863,1.851-2.828    c0.434-0.849,0.645-1.748,0.645-2.748c0-0.989-0.176-1.921-0.521-2.772c-0.344-0.844-0.829-1.561-1.483-2.189    c-0.651-0.625-1.473-1.129-2.44-1.496c-0.991-0.375-2.152-0.564-3.447-0.564c-1.246,0-2.36,0.237-3.31,0.705    c-0.964,0.475-1.747,1.112-2.392,1.948c-0.667,0.864-1.181,1.891-1.528,3.055c-0.355,1.191-0.518,2.518-0.481,3.943    c0.011,0.428-0.152,0.843-0.451,1.15c-0.299,0.307-0.71,0.48-1.139,0.48H30.69c-0.833,0-1.525-0.644-1.585-1.475    c-0.176-2.423,0.074-4.674,0.743-6.69c0.673-2.028,1.692-3.802,3.028-5.273c1.34-1.476,2.962-2.63,4.821-3.429    c1.849-0.794,3.895-1.197,6.081-1.197c2.238,0,4.288,0.336,6.092,0.998c1.836,0.674,3.419,1.645,4.706,2.885    c1.292,1.247,2.296,2.773,2.983,4.539c0.676,1.738,1.018,3.661,1.018,5.717c0,2.224-0.466,4.181-1.385,5.818    c-0.847,1.505-1.892,2.924-3.106,4.215c-1.142,1.212-2.322,2.435-3.544,3.667c-1.114,1.126-2.093,2.402-2.908,3.791    c-0.564,0.951-0.869,1.971-0.936,3.124c-0.078,1.359-0.118,2.71-0.118,4.014C46.579,56.51,45.867,57.222,44.989,57.222z"></path>
                </svg>
              </button>
              <button className="ql-insert-contact">
                <svg viewBox="30 30 50 42" className="ql-fill">
                  <path d="M66,68 C66,68.546814 65.5555144,69 65.0072136,69 L34.9927864,69 C34.4523621,69 34,68.5522847 34,68 C34,59.163444 41.163444,52 50,52 C58.836556,52 66,59.163444 66,68 Z M50,50 C45.0294373,50 41,45.9705627 41,41 C41,36.0294373 45.0294373,32 50,32 C54.9705627,32 59,36.0294373 59,41 C59,45.9705627 54.9705627,50 50,50 Z"></path>
                </svg>
              </button>
            </span>
            <span className="ql-formats">
              <button className="ql-clean" />
            </span>
          </div>
          <ReactQuill onChange={value => this.handleChange("content", value)}
            value={this.state.template && this.state.template.content}
            formats={["header", "list", "bullet", "bold", "italic", "underline", "link", "NPS", "ContactName"]}
            modules={{
              toolbar: {
                container: "#toolbar",
                handlers: {
                  "insert-nps": insertNPS,
                  "insert-contact": insertContactName
                }
              },
              clipboard: {
                matchVisual: false,
              }
            }}/>

          <details>
            <summary>Advanced Options</summary>

            <label>Thank You Message</label>
            <ReactQuill onChange={value => this.handleChange("thankMessage", value)}
              value={this.state.template && this.state.template.thankMessage}
              formats={["header", "list", "bullet", "bold", "italic", "underline", "link"]} />

            <label>Promoter Open Question</label>
            <ReactQuill onChange={value => this.handleChange("promoterCommentQuestion", value)}
              value={this.state.template && this.state.template.promoterCommentQuestion}
              formats={["header", "list", "bullet", "bold", "italic", "underline", "link"]} />

            <label>Passive Open Question</label>
            <ReactQuill onChange={value => this.handleChange("passiveCommentQuestion", value)}
              value={this.state.template && this.state.template.passiveCommentQuestion}
              formats={["header", "list", "bullet", "bold", "italic", "underline", "link"]} />

            <label htmlFor="detractorCommentQuestion">Detractor Open Question</label>
            <ReactQuill onChange={value => this.handleChange("detractorCommentQuestion", value)}
              value={this.state.template && this.state.template.detractorCommentQuestion}
              formats={["header", "list", "bullet", "bold", "italic", "underline", "link"]} />

            <label htmlFor="customJavaScript">Custom JavaScript <div className="info inline">Custom code to be injected into the respondants browser for analytics or ad retargeting.</div></label>
            <textarea onChange={value => this.handleChange("customJavaScript", value)}
              value={this.state.template && this.state.template.customJavaScript}
              spellCheck="false"
              className="u-full-width code"
              placeholder=""
              id="customJavaScript" />
          </details>

          <input disabled={!this.validateForm()}
            className={"button-primary" + (this.state.isLoading ? " loading" : "")}
            type="submit"
            value="Save Draft" />

          { this.props.match.params.id !== "new" &&
            <button onClick={this.handleDelete}
              className={this.state.isDeleting && "loading"}
              type="button">
              Delete
            </button>
          }

          <button onClick={this.handleSend}
            disabled={!this.validateForm()}
            type="button"
            className={"u-pull-right" + (this.state.isSending ? " loading" : "")}>
            Send Now
          </button>

          <button onClick={this.handleTestSend}
            disabled={!this.validateForm()}
            type="button"
            className={"u-pull-right" + (this.state.isSending ? " loading" : "")}>
            Test
          </button>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="Template">
        { this.state.template && this.state.template.sent &&
          <h3>{this.state.template.internalName || this.state.template.subject}</h3>
        }
        { this.state.template && this.state.template.sent && this.renderResponseStats() }
        { this.state.template && this.state.template.sent &&
          this.state.template.responses && this.state.template.responses.length == 0 &&
          <div className="info">
            This email was sent on {new Date(this.state.template.sent).toLocaleString()} but it looks like nobody responded yet — check back later.
          </div>
        }
        { this.state.template && this.state.template.sent && this.renderDeliveryStats() }
        { this.state.template && this.state.template.sent && this.renderResponses() }
        {this.state.template && this.renderComposeForm()}

        <AlertModal ref="alertModal" />
        <ConfirmModal ref="confirmModal" />
        <PromptModal ref="promptModal" />
        <TestSendModal ref="testSendModal" />
      </div>
    );
  }
}
