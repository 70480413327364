import React from "react";
import PromiseModal from "./PromiseModal";

export default class TestSendModal extends PromiseModal {
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  renderBody() {
    return (
      <div className="modal-container">
        <div className="modal">
          <form onSubmit={(e) => { e.preventDefault(); this.resolve(this.state); }}>
            <h3>Sending Test Email</h3>
            <p>Who would you like to send the test email to?</p>
            <label htmlFor="name">Recipient Name <div className="info inline">Leave empty for default value.</div></label>
            <input type="text"
              id="name"
              placeholder="Sally"
              onChange={this.handleChange}
              className="u-full-width"
              value={this.state.response} />

            <label htmlFor="name">Recipient Email</label>
            <input type="email"
              id="email"
              placeholder="example@tiny-nps.com"
              onChange={this.handleChange}
              className="u-full-width"
              value={this.state.response} />

            <input className="button-primary" type="submit" value="Send" />

            <button onClick={(e) => { e.preventDefault(); this.resolve(null); }}
              className="u-pull-right"
              type="button">
              Cancel
            </button>
          </form>
        </div>
      </div>
    );
  }
}
