import React from "react";
import PromiseModal from "./PromiseModal";

export default class CSVImportModal extends PromiseModal {
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  renderBody() {
    const fields = this.state.columns;

    return (
      <div className="modal-container">
        <div className="modal">
          <form onSubmit={(e) => { e.preventDefault(); this.resolve(this.state); }}>
            <h3>Choose Columns</h3>
            <p>Looks like there are {fields.length} columns named {fields.join(", ")} in the file you've uploaded.</p>
            <p>
              Please select the corresponding columns for the contact emails and names.
            </p>
            <div className="row">
              <div className="six columns">
                <label htmlFor="email">Email Column</label>
                <select value={this.state.email}
                  onChange={this.handleChange}
                  className="u-full-width"
                  id="email">
                  { fields.map((field) => <option key={field} value={field}>{field}</option>) }
                </select>
              </div>
              <div className="six columns">
                <label htmlFor="name">Name Column</label>
                <select value={this.state.name}
                  onChange={this.handleChange}
                  className="u-full-width"
                  id="name">
                  { fields.map((field) => <option key={field} value={field}>{field}</option>) }
                </select>
              </div>
            </div>
            <input className="button-primary" type="submit" value="Upload" />
            <div className="u-pull-right">
              <button onClick={(e) => { e.preventDefault(); this.resolve(null); }}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
