import React, { Component } from "react";
import { Storage, Auth } from "aws-amplify";
import config from "../config";
import AlertModal from "./AlertModal";
import PromptModal from "./PromptModal";
import { s3Upload } from "../libs/awsLib"
import "./Settings.css";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = user.attributes;
      let attachmentURL = null;

      attributes["custom:unsubscribeText"] = attributes["custom:unsubscribeText"] || "Sorry to see you go.";

      if (attributes["custom:logoUrl"]) {
        attachmentURL = config.s3.BASE_URL + attributes["custom:logoUrl"];
      } else {
        attributes["custom:logoUrl"] = "";
      }

      this.setState({
        user,
        attachmentURL,
        ...attributes
      });
    } catch (e) {
      await this.refs.alertModal.show({
        message: "Something went wrong.",
        title: "Oops",
      });
    }
  }

  validateForm() {
    return true;
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.props.userHasAuthenticated(false);

    this.props.history.push("");
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    const logoFile = event.target.logo && event.target.logo.files && event.target.logo.files[0];
    if (logoFile && logoFile.size > config.MAX_ATTACHMENT_SIZE) {
      await this.refs.alertModal.show({
        message: `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`,
        title: "Logo too big",
      });
      return;
    }

    this.setState({ isLoading: true });
    this.setState({ isSaved: false });

    try {
      const attachment = logoFile
        ? await s3Upload(logoFile)
        : "";

      const result = await Auth.updateUserAttributes(this.state.user, {
        email: this.state.email ? this.state.email : null,
        name: this.state.name ? this.state.name : null,
        address: this.state.address ? this.state.address : null,
        "custom:unsubscribeText": this.state["custom:unsubscribeText"],
        "custom:logoUrl": attachment ? attachment : this.state["custom:logoUrl"]
      });

      // did the email change? then we need to confirm
      if (this.state.email != this.state.user.attributes.email) {
        const code = await this.refs.promptModal.show({
          message: "You've changed your email address. Please check your inbox for the verification code we've sent you.",
          response: "",
          title: "Please verify email",
        });
        await Auth.verifyCurrentUserAttributeSubmit("email", code);

      }
      this.setState({ isLoading: false });
      this.setState({ isSaved: true });

      // trigger onboarding
      if (this.props.getOnboardingStage() === "settings") {
        await this.props.setOnboardingStage("contacts");
      }
    } catch (e) {
      await this.refs.alertModal.show({
        message: e.message || "Something went wrong. Please try again.",
        title: "Oops",
      });
      this.setState({ isLoading: false });
    }
  }

  handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          attachmentURL: e.target.result
        })
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  render() {
    const logoBoxStyles = {
      backgroundImage: `url(${ this.state.attachmentURL || "/images/upload.png"})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "60%",
      backgroundPosition: "center"
    };

    return (
      <div className="Settings">
        {this.state.isSaved &&
          <div className="info">
            Settings saved.
          </div>
        }

        {this.state.user && (!this.state.address || !this.state.name) &&
          <div className="info">
            Hi there 👋
            <br />
            Please enter your details below to get started.
          </div>
        }
        {this.state.user &&
          <div className="user-form">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="four columns">
                  <label>Logo</label>
                  <label className="logo-box" style={logoBoxStyles} htmlFor="logo"></label>
                  <input onChange={this.handleFileChange}
                    className="logo-box-input"
                    type="file"
                    id="logo" />
                </div>

                <div className="eight columns contact-container">
                  <label htmlFor="name">Name <div className="info inline">This will be the "from" name in your emails.</div></label>
                  <input onChange={this.handleChange}
                    value={this.state.name}
                    className="u-full-width"
                    type="text"
                    placeholder="Jack from ACME"
                    id="name" />

                  <label htmlFor="email">Email <div className="info inline">This will be the "reply to" address in your emails.</div></label>
                  <input onChange={this.handleChange}
                    value={this.state.email}
                    className="u-full-width"
                    type="email"
                    placeholder="example@tiny-nps.com"
                    id="email" />
                </div>
              </div>

              <label htmlFor="address">Address <div className="info inline">A valid address is required for regulatory reasons.</div></label>
              <input onChange={this.handleChange}
                value={this.state.address}
                className="u-full-width"
                type="text"
                placeholder="1 Tiny Lane, London, UK"
                id="address" />

              <label htmlFor="custom:unsubscribeText">Unsubscribe Message <div className="info inline">People will see this message if they click the unsubscribe link in your emails.</div></label>
              <textarea onChange={this.handleChange}
                value={this.state["custom:unsubscribeText"]}
                className="u-full-width"
                placeholder=""
                id="custom:unsubscribeText" />

              <input disabled={!this.validateForm()}
                className={"button-primary" + (this.state.isLoading ? " loading" : "")}
                type="submit"
                value="Save" />

              <button
                onClick={this.handleLogout}
                className="u-pull-right"
                type="button">
                Logout
              </button>
            </form>
          </div>
        }
        <AlertModal ref="alertModal" />
        <PromptModal ref="promptModal" />
      </div>
    );
  }
}
