import React from "react";
import { Route, Switch } from "react-router-dom";
import Templates from "./containers/Templates";
import Contacts from "./containers/Contacts";
import Template from "./containers/Template";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Settings from "./containers/Settings";
import Respond from "./containers/Respond";
import NotFound from "./containers/NotFound";
import Landing from "./containers/Landing";
import Unsubscribe from "./containers/Unsubscribe";
import Privacy from "./containers/Privacy";
import AboutNPS from "./containers/AboutNPS";
import Terms from "./containers/Terms";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <UnauthenticatedRoute path="/" exact component={Landing} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <Route path="/respond" exact component={Respond} props={childProps} />
    <Route path="/unsubscribe" exact component={Unsubscribe} props={childProps} />
    <Route path="/privacy" exact component={Privacy} props={childProps} />
    <Route path="/terms" exact component={Terms} props={childProps} />
    <Route path="/about-nps" exact component={AboutNPS} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/contacts" exact component={Contacts} props={childProps} />
    <AuthenticatedRoute path="/email" exact component={Templates} props={childProps} />
    <AuthenticatedRoute path="/email/:id" exact component={Template} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
